import React from "react"
import { graphql } from "gatsby"

import PostsList from "../components/blog/postsList"

const Category = props => {
  const categoryName = props.pageContext.name
  const articles = props.data.articles.nodes
  const category = props.data.category.nodes[0]
  return (
    <PostsList
      titleseo={category.seo.titleseo}
      descriptionseo={category.seo.description}
      articles={articles}
      title={categoryName}
    />
  )
}

export const query = graphql`
  query Category($name: String!) {
    articles: allStrapiArticle(
      filter: { categories: { elemMatch: { name: { eq: $name } } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        author {
          name
          id
          avatar {
            childImageSharp {
              fixed(width: 40, height: 40, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
            childImageSharpSmaller: childImageSharp {
              fixed(width: 20, height: 20, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        title
        slug
        blurb
        image {
          childImageSharp {
            fluid(maxWidth: 480, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        SEO {
          titleseo
          description
        }
        date(formatString: "MMMM Do, YYYY")
        categories {
          name
        }
      }
    }
    category: allStrapiCategory(filter: { name: { eq: $name } }) {
      nodes {
        name
        seo {
          titleseo
          description
        }
      }
    }
  }
`

export default Category
